var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "invoice-page" } },
    [
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center justify-between" },
        [
          _c(
            "vx-input-group",
            { staticClass: "mb-base mr-3" },
            [
              _c("vs-input", {
                attrs: { placeholder: "Email" },
                model: {
                  value: _vm.mailTo,
                  callback: function ($$v) {
                    _vm.mailTo = $$v
                  },
                  expression: "mailTo",
                },
              }),
              _c("template", { slot: "append" }, [
                _c(
                  "div",
                  { staticClass: "append-text btn-addon" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "whitespace-no-wrap",
                        attrs: { type: "border" },
                        on: {
                          click: function ($event) {
                            _vm.mailTo = ""
                          },
                        },
                      },
                      [_vm._v("Send Invoice")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "flex items-center" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mb-base mr-3",
                  attrs: {
                    type: "border",
                    "icon-pack": "feather",
                    icon: "icon icon-download",
                  },
                },
                [_vm._v("Download")]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "mb-base mr-3",
                  attrs: { "icon-pack": "feather", icon: "icon icon-file" },
                  on: { click: _vm.printInvoice },
                },
                [_vm._v("Print")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("vx-card", { attrs: { id: "invoice-container" } }, [
        _c("div", { staticClass: "vx-row leading-loose p-base" }, [
          _c("div", { staticClass: "vx-col w-1/2 mt-base" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/logo/logo.png"),
                alt: "vuexy-logo",
              },
            }),
          ]),
          _c("div", { staticClass: "vx-col w-1/2 text-right" }, [
            _c("h1", [_vm._v("Invoice")]),
            _c("div", { staticClass: "invoice__invoice-detail mt-6" }, [
              _c("h6", [_vm._v("INVOICE NO.")]),
              _c("p", [_vm._v(_vm._s(_vm.invoiceDetails.invoiceNo))]),
              _c("h6", { staticClass: "mt-4" }, [_vm._v("INVOICE DATE")]),
              _c("p", [
                _vm._v(
                  _vm._s(_vm._f("date")(_vm.invoiceDetails.invoiceDate, true))
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-col w-1/2 mt-12" }, [
            _c("h5", [_vm._v("Recipient")]),
            _c("div", { staticClass: "invoice__recipient-info my-4" }, [
              _c("p", [_vm._v(_vm._s(_vm.recipientDetails.fullName))]),
              _c("p", [_vm._v(_vm._s(_vm.recipientDetails.addressLine1))]),
              _c("p", [_vm._v(_vm._s(_vm.recipientDetails.addressLine2))]),
              _c("p", [_vm._v(_vm._s(_vm.recipientDetails.zipcode))]),
            ]),
            _c("div", { staticClass: "invoice__recipient-contact" }, [
              _c(
                "p",
                { staticClass: "flex items-center" },
                [
                  _c("feather-icon", {
                    attrs: { icon: "MailIcon", svgClasses: "h-4 w-4" },
                  }),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v(_vm._s(_vm.recipientDetails.mailId)),
                  ]),
                ],
                1
              ),
              _c(
                "p",
                { staticClass: "flex items-center" },
                [
                  _c("feather-icon", {
                    attrs: { icon: "PhoneIcon", svgClasses: "h-4 w-4" },
                  }),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v(_vm._s(_vm.recipientDetails.mobile)),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "vx-col w-1/2 mt-base text-right mt-12" }, [
            _c("h5", [_vm._v(_vm._s(_vm.companyDetails.name))]),
            _c("div", { staticClass: "invoice__company-info my-4" }, [
              _c("p", [_vm._v(_vm._s(_vm.companyDetails.addressLine1))]),
              _c("p", [_vm._v(_vm._s(_vm.companyDetails.addressLine2))]),
              _c("p", [_vm._v(_vm._s(_vm.companyDetails.zipcode))]),
            ]),
            _c("div", { staticClass: "invoice__company-contact" }, [
              _c(
                "p",
                { staticClass: "flex items-center justify-end" },
                [
                  _c("feather-icon", {
                    attrs: { icon: "MailIcon", svgClasses: "h-4 w-4" },
                  }),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v(_vm._s(_vm.companyDetails.mailId)),
                  ]),
                ],
                1
              ),
              _c(
                "p",
                { staticClass: "flex items-center justify-end" },
                [
                  _c("feather-icon", {
                    attrs: { icon: "PhoneIcon", svgClasses: "h-4 w-4" },
                  }),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v(_vm._s(_vm.companyDetails.mobile)),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "p-base" },
          [
            _c(
              "vs-table",
              {
                attrs: { hoverFlat: "", data: _vm.invoiceData.tasks },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ data }) {
                      return _vm._l(data, function (tr, index) {
                        return _c(
                          "vs-tr",
                          { key: index },
                          [
                            _c("vs-td", { attrs: { data: data[index].task } }, [
                              _vm._v(_vm._s(data[index].task)),
                            ]),
                            _c(
                              "vs-td",
                              { attrs: { data: data[index].hours } },
                              [_vm._v(_vm._s(data[index].hours))]
                            ),
                            _c("vs-td", { attrs: { data: data[index].rate } }, [
                              _vm._v(_vm._s(data[index].rate) + " USD"),
                            ]),
                            _c(
                              "vs-td",
                              { attrs: { data: data[index].amount } },
                              [_vm._v(_vm._s(data[index].amount) + " USD")]
                            ),
                          ],
                          1
                        )
                      })
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "thead" },
                  [
                    _c("vs-th", { staticClass: "pointer-events-none" }, [
                      _vm._v("TASK DESCRIPTION"),
                    ]),
                    _c("vs-th", { staticClass: "pointer-events-none" }, [
                      _vm._v("HOURS"),
                    ]),
                    _c("vs-th", { staticClass: "pointer-events-none" }, [
                      _vm._v("RATE"),
                    ]),
                    _c("vs-th", { staticClass: "pointer-events-none" }, [
                      _vm._v("AMOUNT"),
                    ]),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "vs-table",
              {
                staticClass: "w-1/2 ml-auto mt-4",
                attrs: { hoverFlat: "", data: _vm.invoiceData },
              },
              [
                _c(
                  "vs-tr",
                  [
                    _c("vs-th", { staticClass: "pointer-events-none" }, [
                      _vm._v("SUBTOTAL"),
                    ]),
                    _c("vs-td", [
                      _vm._v(_vm._s(_vm.invoiceData.subtotal) + " USD"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "vs-tr",
                  [
                    _c("vs-th", { staticClass: "pointer-events-none" }, [
                      _vm._v(
                        "DISCOUNT (" +
                          _vm._s(_vm.invoiceData.discountPercentage) +
                          "%)"
                      ),
                    ]),
                    _c("vs-td", [
                      _vm._v(_vm._s(_vm.invoiceData.discountedAmount) + " USD"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "vs-tr",
                  [
                    _c("vs-th", { staticClass: "pointer-events-none" }, [
                      _vm._v("TOTAL"),
                    ]),
                    _c("vs-td", [
                      _vm._v(_vm._s(_vm.invoiceData.total) + " USD"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "invoice__footer text-right p-base" }, [
          _c("p", { staticClass: "mb-4" }, [
            _vm._v(
              "Transfer the amounts to the business amount below. Please include invoice number on your check."
            ),
          ]),
          _c("p", [
            _c("span", { staticClass: "mr-8" }, [
              _vm._v("BANK: "),
              _c("span", { staticClass: "font-semibold" }, [
                _vm._v("FTSBUS33"),
              ]),
            ]),
            _c("span", [
              _vm._v("IBAN: "),
              _c("span", { staticClass: "font-semibold" }, [
                _vm._v(" G882-1111-2222-3333 "),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }